import {endpoints} from './endpoints'
import request from '../helpers/request'

export function cameraList(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.cameraList,
      method: 'post'
    })
		  .then(function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    resolve(error)
		  });
	})
}