<template>
  <div v-if="buttonData">
    <CModal
      title="Botón SOS"
      color="primary"
      class="modal-alerta"
      :show.sync="showModal"
    >
      <template #header>
        <p class="mb-0 text-center w-100">Botón SOS ID: {{buttonData.deviceID}}</p>
        <CButton
          class="mx-2 p-0"
          @click="closeModal()"
        >
          <p class="text-white mb-0 font-weight-bold">X</p>
        </CButton>
      </template>
      <CRow>
        <CCol col="12" xl="12">
          <CRow>
            <CCol col="6" xl="6">
              <div class="d-flex w-100 flex-column h-100">
                <div class="d-flex justify-content-center align-items-center text-center mb-3 py-2 bg-primary text-white rounded-top">
                  <p class="mb-0">Ubicación del Botón</p>
                </div>
                <GmapMap
                  v-if="buttonData"
                  :center="formatPosition(buttonData)"
                  :zoom="12"
                  class="map-container"
                  style="min-height: 300px; height: 100%">
                    <GmapMarker
                      :position="formatPosition(buttonData)"
                      :icon="{url: require('../../assets/imgicons/ICONOS SOSRecurso 8.png'), scaledSize: { width: 28, height: 35 }}"
                      @click="startMonitoreo()"
                    />
                </GmapMap>
              </div>
            </CCol>
            <CCol col="6" xl="6">
              <CRow  v-if="!modalInfo || loadingInfo">
                <CCol col="12" xl="12">
                  <div
                      style="text-align: center;min-height: 50vh;flex: 1;align-items: center;justify-content: center;display: flex;flex-direction: column;">
                    <CSpinner
                      style="width:4rem;height:4rem;"
                      color="primary"
                      grow
                    />
                    Cargando información del botón
                  </div>
                </CCol>
              </CRow>
              <CRow  v-else>
                <CCol col="12" xl="12">
                  <div class="border-primary px-0 rounded">
                    <div class="d-flex justify-content-between align-items-center p-2 bg-primary text-white rounded-top">
                      <p class="mb-0 ">Botón SOS</p>
                      <p class="mb-0">
                        <CImg
                          class="white-color-image-overlay mr-3"
                          height="18"
                          :src="require('../../assets/imgicons/ICONOS SOSRecurso 23@2x.png')"
                        />
                        {{modalInfo.button_id}}
                      </p>
                    </div>
                    <CRow class="p-3">
                      <CCol class="d-flex" col="12" xl="12">
                        <div class="mr-4">
                          <img
                            v-if="modalInfo.user.info.avatar"
                            :src="modalInfo.user.info.avatar"
                            class="c-mb-2 avatar-alerta-user"
                          />
                          <div class="icon-alerta-user" v-else><font-awesome-icon icon="user"/></div>
                        </div>
                        <div>
                          <p class="mb-0 text-primary font-weight-bold">{{modalInfo.user.name}}</p>
                          <p class="mb-0 text-primary d-flex align-items-center">
                            <CImg
                              class="mr-2"
                              height="12"
                              :src="require('../../assets/imgicons/ICONOS SOSRecurso 22@2x.png')"
                            />
                            {{modalInfo.user.email}}
                          </p>
                          <p class="mb-0 text-primary d-flex align-items-center">
                            <CImg
                              class="mr-2"
                              height="12"
                              :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"
                            />{{modalInfo.user.info.telefono}}
                              <CButton
                                :disabled="$store.state.toCallData ? true:false"
                                color="primary"
                                class="ml-2 my-1"
                                @click="call(modalInfo.user.info.telefono, modalInfo.user.name)"
                                size="sm"
                              >
                                <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                Llamar
                              </CButton>
                          </p>
                          <p class="mb-0 text-primary d-flex align-items-center">
                            <CImg
                              class="mr-2"
                              height="12"
                              :src="require('../../assets/imgicons/ICONOS SOSRecurso 21@2x.png')"
                            />{{modalInfo.user.info.direccion}}
                          </p>
                        </div>
                      </CCol>
                    </CRow>
                    <CRow class="px-3">
                      <div
                        class="d-flex flex-column justify-content-center p-3 w-100">
                        <p class="mb-2 text-primary font-weight-bold">Datos del Botón</p>
                        <p class="mb-0 text-primary">Alias Botón SOS: {{modalInfo.name}}</p>
                        <p class="mb-0 text-primary">ID Botón SOS: {{modalInfo.button_id}}</p>

                        <CRow>
                          <CCol class="d-flex flex-column my-2" col="6" xl="6">
                            <p class="mb-0 text-primary">
                              Teléfono Botón: {{modalInfo.phone}}
                              <CButton
                                :disabled="$store.state.toCallData ? true:false"
                                color="primary"
                                class="ml-2 my-1"
                                @click="call(modalInfo.phone,modalInfo.user.name)"
                                size="sm"
                              >
                                <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                Llamar
                              </CButton>
                            </p>
                            <p class="mb-0 text-primary" v-if="modalInfo.info && modalInfo.info.sos1">
                              Teléfono SOS 1: {{modalInfo.info.sos1}}
                              <CButton
                                :disabled="$store.state.toCallData ? true:false"
                                color="primary"
                                class="ml-2 my-1"
                                @click="call(modalInfo.info.sos1,modalInfo.user.name)"
                                size="sm"
                              >
                                <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                Llamar
                              </CButton>
                            </p>
                          </CCol>
                          <CCol class="d-flex flex-column my-2" col="6" xl="6">
                            <p class="mb-0 text-primary" v-if="modalInfo.info && modalInfo.info.sos2">
                              Teléfono SOS 2: {{modalInfo.info.sos2}}
                              <CButton
                                :disabled="$store.state.toCallData ? true:false"
                                color="primary"
                                class="ml-2 my-1"
                                @click="call(modalInfo.info.sos2,modalInfo.user.name)"
                                size="sm"
                              >
                                <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                Llamar
                              </CButton>
                            </p>
                            <p class="mb-0 text-primary" v-if="modalInfo.info && modalInfo.info.sos3">
                              Teléfono SOS 3: {{modalInfo.info.sos3}}
                              <CButton
                                :disabled="$store.state.toCallData ? true:false"
                                color="primary"
                                class="ml-2 my-1"
                                @click="call(modalInfo.info.sos3,modalInfo.user.name)"
                                size="sm"
                              >
                                <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 20@2x.png')"/>
                                Llamar
                              </CButton>
                            </p>
                          </CCol>
                        </CRow>
                      </div>
                    </CRow>
                    <CRow>
                      <CCol col="12" xl="12">
                        <div style="border-top-color: #d0282e!important;" class="w-100 px-3 py-2 border-top d-flex justify-content-end">
                        <CButton @click="closeModal()" color="success">
                          <img style="margin-right: 5px;" class="white-color-image-overlay" height="12" :src="require('../../assets/imgicons/ICONOS SOSRecurso 19@2x.png')"/>
                          Cerrar
                        </CButton>
                      </div>
                      </CCol>
                    </CRow>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="closeModal" color="primary" size="lg">Salir</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import {closeAlert, setAlertInfo} from '../../api/alerts'
import {getButtonData} from '../../api/buttons'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'ModalButton',
  props: ['showModal', 'buttonData', 'closeModal'],
  components: {'v-select': vSelect},
  data () {
    return {
    	socket: null,
    	callNumber: '',
      loadingInfo: true,
      modalInfo: null
    }
  },
  watch: {
    buttonData: function (data) {
      if(data){
        this.getData(data)
      }
    }
  },
  mounted(){
  },
  methods: {
    call(phoneToCall, name){
      const self = this
      const callData = {
        phoneToCall,
        name
      }
      this.$store.commit("setCallData", callData)
    },
    getData(button){
      const self = this
      getButtonData({button_id: button.deviceID})
        .then(function(resp){
          self.modalInfo = resp.data
          self.loadingInfo = false
        })
        .catch(function(error){
          console.log(error)
        })
    },
  	hexToDec(hex) {
    return hex.toLowerCase().split('').reduce( (result, ch) =>
        result * 16 + '0123456789abcdefgh'.indexOf(ch), 0);
		},
		decimalToHexString(number){
		  if (number < 0){
		    number = 0xFFFFFFFF + number + 1;
		  }
		  return number.toString(16).toUpperCase();
		},
    formatPosition(position){
      const geo = {
        lat: position.latitude,
        lng: position.longitude
      }
      return geo
    },
    setCommand(command){
    	firebase.database(this.$store.state.primaryDB).ref('/Iduam/Buttons/1/' + this.buttonData.deviceID).update({command})
    }
  }
}
</script>

<style type="text/css" media="screen">
  .select-cierre-tipo .vs__search::placeholder,
  .select-cierre-tipo .vs__dropdown-toggle,
  .select-cierre-tipo .vs__dropdown-menu,
  .select-cierre-tipo .vs__open-indicator{
    color: #d0282e;
    border-color: #d0282e;
  }
</style>