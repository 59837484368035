<template>
    <div>
        <CRow>
			<CCol col="12" sm="6">
    			<CSelect
    			  label="Dispositivo de entrada"
    			  :options="inputDevices"
				  v-model="audioInputDevice"
    			/>
			</CCol>
			
			<CCol col="12" sm="6">
    			<CSelect
    			  label="Dispositivo de salida"
    			  :options="outputDevices"
				  v-model="audioOutputDevice"
    			/>
			</CCol>
		</CRow>
    </div>
</template>

<script>
export default {
    data () {
        return {
			outputDevices: [],
			inputDevices: [],
        }
    },
    computed: {
        audioOutputDevice: {
        get () {
        return this.$store.state.audioOutputDevice
        },
        set (value) {
        this.$store.commit('setAudioOutputDevice', value)
        }
  },
        audioInputDevice: {
        get () {
        return this.$store.state.audioInputDevice
        },
        set (value) {
        this.$store.commit('setAudioInputDevice', value)
        }
  },
    },
    methods: {
		listAudioDevices() {
		    navigator.mediaDevices.enumerateDevices().then(devices => {

		    const outs = [...devices].filter(device => device.kind == 'audiooutput')
		    const inputs = [...devices].filter(device => device.kind == 'audioinput')

		    outs.forEach(device => device.value = device.deviceId)
		    inputs.forEach(device => device.value = device.deviceId)

		    this.outputDevices = Object.values([...outs].reduce((unique, o) => {if(!unique[o.groupId]) unique[o.groupId] = o;
                return unique;
            }, {}));
            
            this.inputDevices = Object.values([...inputs].reduce((unique, o) => {if(!unique[o.groupId]) unique[o.groupId] = o;
                return unique;
            }, {}));

            if (!this.$store.state.audioOutputDevice || !this.outputDevices.filter(device=> device.value == this.$store.state.audioOutputDevice).length) {
                this.$store.commit('setAudioOutputDevice', [...outs].filter(device => device.deviceId == 'default')[0].deviceId)
            }
            if (!this.$store.state.audioInputDevice || !this.inputDevices.filter(device=> device.value == this.$store.state.audioInputDevice).length) {
                console.log('zzzzzzzzzzzzz');
                this.$store.commit('setAudioInputDevice', [...inputs].filter(device => device.deviceId == 'default')[0].deviceId)
            }
		})
	  },
    },
    mounted() {
        this.listAudioDevices()

        navigator.mediaDevices.ondevicechange = (event) => {
            this.listAudioDevices()
        };
    },
}
</script>

<style lang="scss" scoped>

</style>