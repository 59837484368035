<template>
  <div class="w-100 p-0 map-container-dashboard">
<!--     Configuración Audio -->
    <CModal
      title="Ajustes de audio"
      color="primary"
      :show.sync="$store.state.modalAudioSettings"
      class="modal-alerta modal-audio" style="z-index: 9999999999999 !important; position: absolute;">
        <AudioSettings  v-if="$store.state.modalAudioSettings"/>
    </CModal>
    <GmapMap ref="map" :center="$store.state.mapCenter.center" :zoom="$store.state.mapCenter.zoom"
      :options="{ streetViewControl: false, mapTypeControl: false, zoomControl: false, }"
      class="map-container" :style="'height: ' + containerHeight + 'px'" >


<!--       CLUSTER EXPAND -->
<div v-for="(m, index) in clusterMarkers" :key="index + 'o'">
          <GmapMarker
          v-if="m.type == 'home'"
          :visible="showUsersAddress && $store.state.dashboardMap == 'general'"
          customInfo="Address"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/home_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @mouseout="outCluster" @mouseover="inCluster"
          @click="requestUserInfo(m.id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.id)"
            :userInfo="m"
          />
        </GmapMarker>
        <TestInProgress :ref="index" v-if="m.type == 'testinprogress'" :testInfo="m"/>

        <div v-if="$store.state.dashboardMap == 'general'">
        <GmapMarker
          v-if="m.type == 'device'"
          :visible="showPersonales && !checkIfMonitorInAlert(m.deviceID)"
          :position="{lat: m.latitud, lng: m.longitud}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/device_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m, index, 'device')"
        >
          <ContentInfo
            v-if="usersInfo.includes(m.id_usuario) && isDevice"
            :closeInfo="closeContentInfo"
            :componentStatus="infoStatus.includes(m.id_usuario)"
            :userInfo="m"
          />
        </GmapMarker>
        </div>
        <GmapMarker
          v-if="m.type == 'alerta'"
          :visible="showAlertas && $store.state.dashboardMap == 'general'"
          customInfo="Work"
          :animation="1"
          :position="m.position"
          :clickable="true"
          :icon="{url: require('../assets/imgicons/alert_marker.png'), scaledSize:{ width: 55, height: 55 }}"
          @click="showModalAlert(m)"
        />

<!--         smartbands -->
          <GmapMarker
          v-if="m.type == 'smartband'"
          :visible="showSmartbands && $store.state.dashboardMap == 'smartbands'"
          customInfo="Address"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/smartband.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
        <GmapMarker
          v-if="m.type == 'no_smartband'"
          :visible="showNoSmartbands && $store.state.dashboardMap == 'smartbands'"
          customInfo="No Smartband"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/no_smartband.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
        <GmapMarker
          v-if="m.type == 'delivered_smartband'"
          :visible="showDeliveredSmartbands && $store.state.dashboardMap == 'smartbands'"
          customInfo="Delivered Smartband"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/smartbanddelivered_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>

        <!-- feedback -->
        <GmapMarker
          v-if="m.type == 'ok'"
          :visible="showOk && $store.state.dashboardMap == 'feedback'"
          customInfo="Ok"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/okmarker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
        <GmapMarker
          v-if="m.type == 'regular'"
          :visible="showRegular && $store.state.dashboardMap == 'feedback'"
          customInfo="Rewgular"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/regularmarker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
        <GmapMarker
          v-if="m.type == 'bad'"
          :visible="showBad && $store.state.dashboardMap == 'feedback'"
          customInfo="Bad"
          :position="m.location"
          :icon="{url: require('../assets/imgicons/badmarker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>


<!-- CLUSTER MARKERS-->
    <!-- GENERAL MARKERS -->
      <div v-if="showTests && $store.state.dashboardMap == 'general'">
         <GmapCluster v-if="showClusters" ref="markercluster" :styles="clusterStyles" @click="clusterClick" :averageCenter="true" :zoomOnClick="true" :ignoreHidden="true" @mouseout="outCluster" @mouseover="inCluster">
			    <TestInProgress  v-for="(test, index) in testsInProgress" :key="index" :testInfo="test"/>
         </GmapCluster>
			    <TestInProgress v-else  v-for="(test, index) in testsInProgress" :key="index" :testInfo="test"/>
      </div>
      <GmapCluster v-if="showClusters" ref="markercluster" :styles="clusterStyles" @click="clusterClick" :averageCenter="true" :zoomOnClick="true" :ignoreHidden="true" @mouseout="outCluster" @mouseover="inCluster">
        <GmapMarker
          :key="index"
          :ref="index"
          :visible="showAlertas && $store.state.dashboardMap == 'general'"
          v-for="(m, index) in alertas"
          customInfo="Work"
          :animation="1"
          :position="m.position"
          :clickable="true"
          :icon="{url: require('../assets/imgicons/alert_marker.png'), scaledSize:{ width: 55, height: 55 }}"
          @click="showModalAlert(m)"
        />
      </GmapCluster>
        <GmapMarker
        v-else
          :key="index"
          :ref="index"
          :visible="showAlertas && $store.state.dashboardMap == 'general'"
          v-for="(m, index) in alertas"
          customInfo="Work"
          :animation="1"
          :position="m.position"
          :clickable="true"
          :icon="{url: require('../assets/imgicons/alert_marker.png'), scaledSize:{ width: 55, height: 55 }}"
          @click="showModalAlert(m)"
        />
      
      <GmapCluster v-if="showClusters" ref="markercluster" :styles="clusterStyles" @click="clusterClick" :averageCenter="true" :zoomOnClick="true" :ignoreHidden="true" @mouseout="outCluster" @mouseover="inCluster">
        <div v-if="showUsersAddress && $store.state.dashboardMap == 'general'">
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in usersAddress"
          customInfo="Address"
          :position="m.position"
          :icon="{url: require('../assets/imgicons/home_marker.png'), user_id: m.id, markerInfo: m, type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.position.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.position.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.position.user_id)"
            :userInfo="m"
          />
        </GmapMarker>
        </div>


      <div v-if="$store.state.dashboardMap == 'general'">
        <GmapMarker
          :key="'p'+index"
          :ref="'p'+index"
          v-for="(m, index) in personales"
          :position="{lat: m.latitud, lng: m.longitud}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/device_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.id_usuario, index, 'device')"
        >
          <ContentInfo
            v-if="usersInfo.includes(m.id_usuario)"
            :closeInfo="closeContentInfoDevice"
            :componentStatus="infoStatus.includes(m.id_usuario)"
            :userInfo="m"
          />
        </GmapMarker>
      </div>

<!--         SmartBands Markers -->
<div v-if="showSmartbands && $store.state.dashboardMap == 'smartbands'">
        <GmapMarker
          :key="index + 'c'"
          :ref="index + 'c'"
          v-for="(m, index) in smartbands"
          customInfo="Smartband"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/smartband.png'), user_id: m.id,  type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showNoSmartbands && $store.state.dashboardMap == 'smartbands'">
        <GmapMarker
          :key="index + 'd'"
          :ref="index + 'd'"
          v-for="(m, index) in no_smartbands"
          customInfo="No Smartband"
          :position="m.address_geocoding"
          :icon="{url: require('../assets/imgicons/no_smartband.png'), user_id: m.address_geocoding.user_id, type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.address_geocoding, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.address_geocoding)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.address_geocoding)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showDeliveredSmartbands && $store.state.dashboardMap == 'smartbands'">
        <GmapMarker
          :key="index + 'h'"
          :ref="index + 'h'"
          v-for="(m, index) in user_smartband_delivered"
          customInfo="Delivered Smartband"
          :position="m.address_geocoding"
          :icon="{url: require('../assets/imgicons/smartbanddelivered_marker.png'), user_id: m.address_geocoding.user_id, type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.address_geocoding, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.address_geocoding)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.address_geocoding)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>

<!--         FEEDBACK MARKERS -->

<div v-if="showOk && $store.state.dashboardMap == 'feedback'">
        <GmapMarker
          :key="index + 'i'"
          :ref="index + 'i'"
          v-for="(m, index) in ok"
          customInfo="Ok"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/okmarker.png'), user_id: m.user_id, type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showRegular && $store.state.dashboardMap == 'feedback'">
        <GmapMarker
          :key="index + 'j'"
          :ref="index + 'j'"
          v-for="(m, index) in regular"
          customInfo="Rewgular"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/regularmarker.png'), user_id: m.user_id, type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showBad && $store.state.dashboardMap == 'feedback'">
          <GmapMarker
          :key="index + 'k'"
          :ref="index + 'k'"
          v-for="(m, index) in bad"
          customInfo="Bad"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/badmarker.png'), user_id: m.user_id, type: m.type, scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>    
</div>   
      </GmapCluster>

<!--     Sin Clusters -->
        <div v-else >
      <div v-if="showUsersAddress && $store.state.dashboardMap == 'general'">
        <GmapMarker
          :key="index"
          :ref="index"
          v-for="(m, index) in usersAddress"
          customInfo="Address"
          :position="m.position"
          :icon="{url: require('../assets/imgicons/home_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.position.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.position.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.position.user_id)"
            :userInfo="m"
          />
        </GmapMarker>
          </div>

          <div v-if="$store.state.dashboardMap == 'general'">
        <GmapMarker
          :key="'p'+index"
          :ref="'p'+index"
          :visible="showPersonales && !checkIfMonitorInAlert(m.deviceID)"
          v-for="(m, index) in personales"
          :position="{lat: m.latitud, lng: m.longitud}"
          :clickable="true"
          customInfo="Work"
          :icon="{url: require('../assets/imgicons/device_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.id_usuario, index, 'device')"
        >
          <ContentInfo
            v-if="usersInfo.includes(m.id_usuario)"
            :closeInfo="closeContentInfoDevice"
            :componentStatus="infoStatus.includes(m.id_usuario)"
            :userInfo="m"
          />
        </GmapMarker>
          </div>


<!--         Smartband markers -->
<div v-if="showSmartbands && $store.state.dashboardMap == 'smartbands'">
        <GmapMarker
          :key="index + 'e'"
          :ref="index + 'e'"
          v-for="(m, index) in smartbands"
          customInfo="Address"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/smartband.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showNoSmartbands && $store.state.dashboardMap == 'smartbands'">
        <GmapMarker
          :key="index + 'f'"
          :ref="index + 'f'"
          v-for="(m, index) in no_smartbands"
          customInfo="Address"
          :position="m.address_geocoding"
          :icon="{url: require('../assets/imgicons/no_smartband.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.address_geocoding, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.address_geocoding)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.address_geocoding)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showDeliveredSmartbands && $store.state.dashboardMap == 'smartbands'">
        <GmapMarker
          :key="index + 'g'"
          :ref="index + 'g'"
          v-for="(m, index) in user_smartband_delivered"
          customInfo="Address"
          :position="m.address_geocoding"
          :icon="{url: require('../assets/imgicons/smartbanddelivered_marker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.address_geocoding, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.address_geocoding)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.address_geocoding)"
            :userInfo="m"
            :user_id="m.user_id"
            :smartbandUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>

<!--         FEEDBACK MARKERS -->

<div v-if="showOk && $store.state.dashboardMap == 'feedback'">
          <GmapMarker
          :key="index + 'l'"
          :ref="index + 'l'"
          v-for="(m, index) in ok"
          customInfo="Ok"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/okmarker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showRegular && $store.state.dashboardMap == 'feedback'">
        <GmapMarker
          :key="index + 'm'"
          :ref="index + 'm'"
          v-for="(m, index) in regular"
          customInfo="Rewgular"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/regularmarker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
<div v-if="showBad && $store.state.dashboardMap == 'feedback'">
        <GmapMarker
          :key="index + 'n'"
          :ref="index + 'n'"
          v-for="(m, index) in bad"
          customInfo="Bad"
          :position="m.user_address_geo"
          :icon="{url: require('../assets/imgicons/badmarker.png'), scaledSize: { width: 45, height: 45 }}"
          @click="requestUserInfo(m.user_id, index)"
        >
          <ContentInfoAddressMarker
            v-if="usersInfo.includes(m.user_id)"
            :closeInfo="closeContentInfoAddressMarker"
            :componentStatus="infoStatus.includes(m.user_id)"
            :userInfo="m"
            :user_id="m.user_id"
            :feedbackUpdateDate="m.updated_at"
          />
        </GmapMarker>
</div>
</div>         


<!--         <TrackingMarkers /> -->
      <MapSoloTracking />
      <GmapPolygon
        v-if="path.length > 0 && polygonReady"
        :path="path"
        ref="polyline"
        :options="{
          strokeColor: '#d0282e',
          strokeOpacity: 0.4,
          fillColor: hoverComuna ? 'gray' : 'white',
          fillOpacity: 0.2
        }"
        @mouseover="hoverComuna = true"
        @mouseout="hoverComuna = false"
        >
      </GmapPolygon>
    </GmapMap>

    <!-- MAP CONTROLS -->
    <div style="position: absolute; top: 30px; left: 30px; z-index: 99">
      <CButton
        v-c-tooltip="'Acercar Mapa'"
        class="mx-1"
        color="success"
        @click="changeZoom()"
      >
        <font-awesome-icon icon="plus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Alejar Mapa'"
        class="mx-1"
        color="primary"
        @click="changeZoom(1)"
      >
        <font-awesome-icon icon="minus" style="font-size: 20px" />
      </CButton>
      <CButton
        v-c-tooltip="'Ir a mi cuadrante'"
        color="success"
        class="mx-1"
        @click="fitCuadrante()"
      >
        <CImg
          class="img white-color-image-overlay"
          width="30"
          :src="require('../assets/imgicons/ICONOS SOSRecurso 30@2x.png')"
        />
      </CButton>
    </div>

<!--     GENERAL FILTERS -->
    <div v-if="$store.state.dashboardMap == 'general'" class="dashboard-map-controllers" :class="mapControllersOpen ? 'opened':'closed'">
      <div class="tooltip1">
      <span class="tooltiptext1">Alertas en mapa</span>
      <CButton
        class="mx-0 p-0"
        @click="toogleAlertas"
      >
        <CImg
          :style="showAlertas ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/alert_marker.png')"
        />
        <div class="badge badge-markers" style="background-color: #cc1818;">
          <span v-if="!loader">{{alertas.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Dispositivos personales</span>
      <CButton
        class="mx-0 p-0"
        @click="tooglePersonales()"
      >
        <CImg
          :style="showPersonales ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/device_marker.png')"
        />
        <div class="badge badge-markers" style="background-color: #009246;">
          <span v-if="!loader">{{personales.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Domicilios en mapa</span>
      <CButton
        class="mx-0 p-0"
        @click="showUsersAddress = !showUsersAddress"
      >
        <CImg
          :style="showUsersAddress ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/home_marker.png')"
        />
        <div class="badge badge-markers" style="background-color: #362058;">
          <span v-if="!loader">{{usersAddress.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Tests en Curso</span>
      <CButton
        class="mx-0 p-0"
        @click="showTests = !showTests"
      >
        <CImg
          :style="showTests ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/testingmarker.png')"
        />
        <div class="badge badge-markers" style="background-color: #004CAF;">
          <span v-if="!loader">{{testsInProgress.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Marcadores Agrupados</span>
      <CButton
        class="mx-0 p-0"
        @click="showClusters = !showClusters"
      >
        <CImg
          :style="showClusters ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="50"
          :src="require('../assets/imgs/cluster.png')"
        />
      </CButton>
      </div>
      <button type="button" class="btn btn-pill btn-primary custom-btn-map-controllers"
        @click="mapControllersOpen = !mapControllersOpen">
        <font-awesome-icon :icon="mapControllersOpen ? 'chevron-left':'chevron-right'"/>
      </button>
    </div>
<!-- SMARTBANDS FILTER -->
    <div v-if="$store.state.dashboardMap == 'smartbands'" class="dashboard-map-controllers-smartbands" :class="mapControllersOpen ? 'opened-smartbands':'closed-smartbands'">
    <div class="tooltip1">
      <span class="tooltiptext1">Usuarios con Smartband</span>
      <CButton
        class="mx-0 p-0"
        @click="toogleSmartBands"
      >
        <CImg
          :style="showSmartbands ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/smartband.png')"
        />
        <div class="badge badge-markers" style="background-color: #009246;">
          <span v-if="!loader">{{smartbands.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
    </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Usuarios sin Smartband</span>
      <CButton
        class="mx-0 p-0"
        @click="toogleNoSmartBands()"
      >
        <CImg
          :style="showNoSmartbands ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/no_smartband.png')"
        />
        <div class="badge badge-markers" style="background-color: #cc1818;">
          <span v-if="!loader">{{no_smartbands.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Pulsera entregada</span>
      <CButton
        class="mx-0 p-0"
        @click="toogleDeliveredSmartBands()"
      >
        <CImg
          :style="showDeliveredSmartbands ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/smartbanddelivered_marker.png')"
        />
        <div class="badge badge-markers" style="background-color: #cc1818;">
          <span v-if="!loader">{{user_smartband_delivered.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Marcadores Agrupados</span>
      <CButton
        class="mx-0 p-0"
        @click="showClusters = !showClusters"
      >
        <CImg
          :style="showClusters ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="50"
          :src="require('../assets/imgs/cluster40.png')"
        />
      </CButton>
      </div>
      <button type="button" class="btn btn-pill btn-primary custom-btn-map-controllers"
        @click="mapControllersOpen = !mapControllersOpen">
        <font-awesome-icon :icon="mapControllersOpen ? 'chevron-left':'chevron-right'"/>
      </button>
    </div>
<!-- FEEDBACK FILTER -->
    <div v-if="$store.state.dashboardMap == 'feedback'" class="dashboard-map-controllers-feedback" :class="mapControllersOpen ? 'opened-feedback':'closed-feedback'">
      <div class="tooltip1">
        <span class="tooltiptext1">Bien</span>
      <CButton
        class="mx-0 p-0"
        @click="toggleShowOk"
      >
        <CImg
          :style="showOk ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/okmarker.png')"
        />
        <div class="badge badge-markers" style="background-color: #009246;">
          <span v-if="!loader">{{ok.length}}</span>
          <div v-else class="loader"></div>
          
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
        <span class="tooltiptext1">Regular</span>
      <CButton
        class="mx-0 p-0"
        @click="toggleShowRegular()"
      >
        <CImg
          :style="showRegular ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/regularmarker.png')"
        />
        <div class="badge badge-markers" style="background-color: #cc1818;">
          <span v-if="!loader">{{regular.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Mal</span>
      <CButton
        class="mx-0 p-0"
        @click="toggleBad()"
      >
        <CImg
          :style="showBad ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="60"
          :src="require('../assets/imgicons/badmarker.png')"
        />
        <div class="badge badge-markers" style="background-color: #cc1818;">
          <span v-if="!loader">{{bad.length}}</span>
          <div v-else class="loader"></div>
        </div>
      </CButton>
      </div>

      <div class="tooltip1">
      <span class="tooltiptext1">Marcadores Agrupados</span>
      <CButton
        class="mx-0 p-0"
        @click="showClusters = !showClusters"
      >
        <CImg
          :style="showClusters ? {opacity: '1'} : { opacity: '.6' }"
          class="img"
          width="50"
          :src="require('../assets/imgs/cluster.png')"
        />
      </CButton>
      </div>
      <button type="button" class="btn btn-pill btn-primary custom-btn-map-controllers"
        @click="mapControllersOpen = !mapControllersOpen">
        <font-awesome-icon :icon="mapControllersOpen ? 'chevron-left':'chevron-right'"/>
      </button>
    </div>
    <modalBoton :showModal="showModalButton" :buttonData="modalButtonData" :closeModal="closeModalButton"/>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/database'
import modalBoton from './widgets/modalBoton'
import { cameraList } from '../api/cameras'
import TrackingMarkers from './widgets/Tracking/TrackingMarkers'
import MapSoloTracking from './widgets/Tracking/MapSoloTracking'
import ContentInfo from './widgets/InfoWindow/ContentInfo'
import ContentInfoAddressMarker from './widgets/InfoWindow/ContentInfoAddressMarker'
import TestInProgress from './widgets/Markers/TestInProgress'
import { mapGetters } from "vuex"
import AudioSettings from './videocall/AudioSettings'
import {getUsersGeo} from '../api/users'
import {getSmartbandsGeo} from '../api/smartbands'
import {getUserFeedbackGeo} from '../api/feedback'
import { AlertTest } from '@/api/alerts'
import _ from 'lodash/lodash'

export default {
  name: 'Dashboard',
  beforeCreate: function() {
      document.body.className = 'home';
  },
  beforeDestroy: function() {
      document.body.classList.remove("home")
  },
  components: {modalBoton, TrackingMarkers, ContentInfo, ContentInfoAddressMarker, MapSoloTracking, TestInProgress, AudioSettings},
  computed: {
    ...mapGetters(["userData"])
  },
  data () {
    return {
      mapControllersOpen: true,
      infoOpen: false,
      infoPos: {
        lat: -33.4373063,
        lng: -70.634297
      },
      containerHeight: 700,
      centerMap: {
        lat: -33.4373063,
        lng: -70.634297
      },
      mapZoom: 12,
      path: [],
      drawingPath: [],
      filter: {
        mujeres: true,
        comercio: true,
        hogar: true
      },
      clusterMarkers: [],
      alertas: [],
      allDevices: [],
      monitores: [],
      personales: [],
      camarasIP: [],
      botonesSOS: [],
			testsInProgress: [],
      testsInProgressOriginal: [],
      monitorear: null,
      showAlertas: true,
      showMonitores: true,
      showPersonales: true,
      showCamaras: false,
      showBotones: true,
      showModalButton: false,
      showUsersAddress: true,
      showEndAlert: false,
      showTests: true,
      showClusters: false,
      modalButtonData: null,
      alertData: null,
      animated: false,
      hoverComuna: false,
      admin: null,
      cuadrante: 0,
      polygonReady: false,
      clusterStyles: [
        {
          textColor: '#0064ED',
          textSize: 14,
          url: require('../assets/imgs/cluster40.png'),
          objectFit: 'contain',
          height: 40,
          width: 40
        },
       {
          textColor: '#0064ED',
          textSize: 14,
          url: require('../assets/imgs/cluster40.png'),
          objectFit: 'contain',
          height: 40,
          width: 40
        },
       {
          textColor: '#0064ED',
          textSize: 14,
          url: require('../assets/imgs/cluster40.png'),
          objectFit: 'contain',
          height: 40,
          width: 40
        }
      ],
      onCluster: false,
      usersInfo: [],
      infoStatus: [],
      usersAddress: [],
      smartbands: [],
      no_smartbands: [],
      user_smartband_delivered: [],
      showSmartbands: true,
      showNoSmartbands: true,
      showDeliveredSmartbands: true,
      ok: [],
      regular: [],
      bad: [],
      showOk: true,
      showRegular: true,
      showBad: true,

      loader: true,
      isDevice: null
    }
  },
  watch:{
    userData: function(data){
      console.log("userData: ", data)
    },
    '$store.state.dashboardMap': function(value) {
      if (value == 'smartbands') {
        this.loader = true;
      getSmartbandsGeo().then(res => {
        this.loader = false;
        this.smartbands = res.user_smartband.map(user => {
          user.type = 'smartband'
          return user
        })
        this.no_smartbands = res.user_no_smartband.map(user => {
          user.type = 'no_smartband'
          return user
        })
        this.user_smartband_delivered = res.user_smartband_delivered.map(user => {
          user.type = 'delivered_smartband'
          return user
        })
    })
    }
      if (value == 'feedback') {
        this.loader = true;
        getUserFeedbackGeo().then(res => {
          this.loader = false;
            const ok = res.feedbacks[Object.keys(res.feedbacks)[0]] ? res.feedbacks[Object.keys(res.feedbacks)[0]] : [];
            this.ok = ok.map(user => {
              user.type = 'ok'
              return user
            })
            const regular = res.feedbacks[Object.keys(res.feedbacks)[1]] ? res.feedbacks[Object.keys(res.feedbacks)[1]] : []
            this.regular = regular.map(user => {
              user.type = 'regular'
              return user
            })

            const bad = res.feedbacks[Object.keys(res.feedbacks)[2]] ? res.feedbacks[Object.keys(res.feedbacks)[2]] : []
            this.bad = bad.map(user => {
              user.type = 'bad'
              return user
            })
          })
    }
  },
    '$store.state.enabledLimitMarkers': function(value){
      this.getGeoUsers();
      
      setTimeout(() => {
        if (value && this.testsInProgressOriginal.length > this.$store.state.limitMarkers) {
          this.testsInProgress = this.testsInProgressOriginal.slice(0, this.$store.state.limitMarkers);
      } else {
        this.testsInProgress = [...this.testsInProgressOriginal]
      }
        },5000);
    },
    '$store.state.limitMarkers': _.debounce(function (value) {
      if (value && value > 100) {
        this.getGeoUsers();

      if (value && this.testsInProgressOriginal.length > value) {
          this.testsInProgress = this.testsInProgressOriginal.slice(0, value);
      } else {
        this.testsInProgress = [...this.testsInProgressOriginal]
      }
      }
    }, 1000)
  },
  async mounted(){
/*         const obj = {user_id: 89}
    AlertTest(obj).then(res => {
      console.log(res);
    }) */
    this.getGeoUsers();
    this.admin = this.$store.state.userData
    this.cuadrante = this.admin.info.cuadrante_id
    this.containerHeight = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight
    this.childAddedListenner()
    this.childRemovedListenner()
    const camaras = await cameraList()
    this.camarasIP = camaras.data
    const self = this
    this.$events.listen('filterSegments', eventData => {
      self.filter = eventData
      self.filterMonitores()
    })
    this.$events.listen('centerMap', eventData => {
      self.centerMap = {
        lat: eventData.latitud,
        lng: eventData.longitud
      }
      self.mapZoom = 18
    })
    setTimeout(function(){
      self.setCuadranteBounds(self.cuadrante)
      self.animated = true;
			self.mapControllersOpen = false
    }, 1000);
  },
  methods: {
    getGeoUsers() {
      const data = this.$store.state.enabledLimitMarkers ? {limit: this.$store.state.limitMarkers} : null;
      getUsersGeo(data).then(res => {
        this.usersAddress = res?.users.map(user => {
          this.loader = false;
          user.position = user?.address_geocoding
          user.type = 'home'
          return user
          })
      })
  },
  closeContentInfoDevice(id_usuario) {
      this.infoStatus.splice(id_usuario, 1)
  },
		getAdminPosition(){
			if(this.admin.id <= 3){
				const options = {
					enableHighAccuracy: true,
					timeout: 10000,
				}
				navigator.geolocation.getCurrentPosition(
					(pos) => {
						const center = {
							center: {
								lat: pos.coords.latitude,
								lng: pos.coords.longitude
							},
							zoom: 12
						}
						this.$store.commit('setMapCenter', center)
					},
					() => this.fitCuadrante(),
					options
				)
			}
			else{
				this.fitCuadrante()
			}
		},
    requestUserInfo(id_usuario, isDevice){
        this.isDevice = isDevice == 'device' ? true : false;
      
      const found = this.usersInfo.findIndex((item) => {
        return item === id_usuario
      })
      if(found === -1){
        this.usersInfo.push(id_usuario)
      } 

      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === id_usuario
      })
      if(foundStatus === -1){
        this.infoStatus.push(id_usuario)
      }
      else{
        this.infoStatus.splice(foundStatus, 1)
      }
    },
    closeContentInfo(id_usuario){
      this.infoStatus.splice(id_usuario, 1)
      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === id_usuario
      })
      if(foundStatus !== -1){
        this.infoStatus.splice(foundStatus, 1)
      }
    },
    closeContentInfoAddressMarker(id_usuario){
      const foundStatus = this.infoStatus.findIndex((item) => {
        return item === id_usuario.user_id ? id_usuario.user_id : id_usuario.id;
      })
      if(foundStatus !== -1){
        this.infoStatus.splice(foundStatus, 1)
      }
    },
    botonClick(m){
      this.showModalButton = true
      this.modalButtonData = m
    },
    closeModalButton(){
      this.showModalButton = false
      this.modalButtonData = null
    },
    inCluster(){
      this.onCluster = true
    },
    outCluster(){
      this.onCluster = false
      const self = this
      setTimeout(function() {
        if(!self.onCluster){
          self.clusterMarkers = []
        }
      }, 3000);
    },
    returnIcon(marker){
      if(marker.camera_id){
        return require('../assets/imgicons/ICONOS SOSRecurso 1-2@2x.png')
      }
      else if(marker.data){
        return require('../assets/imgicons/ICONOS SOSRecurso 2@2x.png')
      }
      else if(marker.deviceData && marker.deviceData.is_monitor){
        return require('../assets/imgicons/ICONOS SOSRecurso 4@2x.png')
      }
      else{
        return require('../assets/imgicons/ICONOS SOSRecurso 5@2x.png')
      }
    },
    markerAction(marker){
      if(marker.camera_id){
        console.log("Camara ip")
      }
      else if(marker.data){
        this.showModalAlert(marker)
      }
      else{
        this.setMonitorear(marker)
      }
      this.clusterMarkers = []
    },
    changeZoom(type = 0){
      this.$refs.map.$mapPromise.then((map) => {
        const actual = map.getZoom()
        type === 0 ? map.setZoom(actual + 1) : map.setZoom(actual - 1)
      })
    },
      clusterClick(cluster) {
  const mapZoom = cluster.map_.getZoom();
  if (mapZoom === 22) {
    const self = this;
    if (self.clusterMarkers.length > 0) {
      self.clusterMarkers = [];
    } else {
      let radius = 1;
      const markers = cluster.getMarkers();
      const initialPos = markers[0].getPosition();
      markers.map(function (item, index) {
        let degrees = 180 + ( index * (360 / (8*radius)) )
        let marcador = {...item?.icon?.markerInfo};
        marcador.location = {
          lat:
            initialPos.lat() - 0.0000065 - ( Math.cos( degrees * (Math.PI/180) ) * 0.00002 * radius ),
          lng:
            initialPos.lng() - ( Math.sin( degrees * (Math.PI/180) ) * 0.000025 * radius )
        };
        marcador.type = item.icon.type
        marcador.user_id = item.icon.user_id
        self.clusterMarkers.push(marcador);
        if(index > 0 && ((index + 1)%(8*radius*Math.pow(2,radius-1)) === 0)){
          radius++
        }
      });
    }
  }
    },
    fitCuadrante(){
      let bounds = new google.maps.LatLngBounds();
      this.path.forEach(paths => {
        paths.forEach(pathItem => {
          bounds.extend(new google.maps.LatLng(pathItem.lat, pathItem.lng))
        })
      })
      this.$refs.map.fitBounds(bounds, 0)
    },
    setCuadranteBounds(id){
      const self = this
      let bounds = new google.maps.LatLngBounds();
      this.$store.state.userData.cuadrante_bounds.map(function(item){
        self.path.push(item.bounds)
        item.bounds.forEach(path => {
          bounds.extend(new google.maps.LatLng(path.lat, path.lng))
        })
      })
			this.getAdminPosition()
      this.polygonReady = true
    },
    filterMonitores(){
      const self = this
      this.monitores = this.allDevices.filter(function(item){
        if(self.filter.mujeres && self.filter.comercio && self.filter.hogar && item.deviceData && item.deviceData.is_monitor){
          return true
        }
        return (item.deviceData && item.deviceData.is_monitor) && ( ( self.filter.mujeres && item.cluster && item.cluster.includes(1) ) || ( self.filter.comercio && item.cluster && item.cluster.includes(2) ) || ( self.filter.hogar && item.cluster && item.cluster.includes(3) ) )
      })
      this.personales = this.allDevices.filter(function(item){
        if(self.filter.mujeres && self.filter.comercio && self.filter.hogar && !(item.deviceData && item.deviceData.is_monitor)){
          return true
        }
        return !(item.deviceData && item.deviceData.is_monitor) && ( ( self.filter.mujeres && item.cluster && item.cluster.includes(1) ) || ( self.filter.comercio && item.cluster && item.cluster.includes(2) ) || ( self.filter.hogar && item.cluster && item.cluster.includes(3) ) )
      })
    },
    toogleMonitor(){
      this.showMonitores = !this.showMonitores
    },
    toogleCamaras(){
      this.showCamaras = !this.showCamaras
    },
    toogleAlertas(){
      this.showAlertas = !this.showAlertas
    },
    tooglePersonales(){
      this.showPersonales = !this.showPersonales
    },
    toogleBotones(){
      this.showBotones = !this.showBotones
    },
    toogleSmartBands(){
      this.showSmartbands = !this.showSmartbands
    },
    toogleNoSmartBands(){
      this.showNoSmartbands = !this.showNoSmartbands
    },
    toogleDeliveredSmartBands(){
      this.showDeliveredSmartbands = !this.showDeliveredSmartbands
    },
    toggleShowOk(){
      this.showOk = !this.showOk
    },
    toggleShowRegular(){
      this.showRegular = !this.showRegular
    },
    toggleBad(){
      this.showBad = !this.showBad
    },
    setMonitorear(data){
      data.showVideo = false
      this.$events.fire('startMonitoreo', data)
    },
    resetMonitor(monitor){
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante + '/' + monitor + '/resetMonitoreo').set(true)
    },
    checkIfMonitorInAlert(deviceID){
      const found = this.alertas.findIndex(function(item, index){
        return item.data.deviceID === deviceID
      })
			if(found !== -1){ return true }
			const foundProgress = this.testsInProgress.findIndex((test) => {
				return test.test_id == deviceID
			})
      return foundProgress === -1 ? false : true 
    },
		setTestsInProgressListenners(){
      firebase.database(this.$store.state.primaryDB).ref('/IduamTests/' + this.cuadrante+ '/testInProgress/').on("child_added", (snapshot) => {
        let testInfo = { ...{test_id: snapshot.key, ...snapshot.val()} }
				const index = this.testsInProgress.findIndex((test) => {
					return test.test_id == snapshot.key
				})
				if(index == -1){
					this.testsInProgress.push(testInfo)
          this.testsInProgressOriginal.push(testInfo)
				}
				else{
          this.testsInProgress.splice(index, 1, testInfo)
				}
          this.$store.commit('setTestsInProgress', this.testsInProgress)
      })
      firebase.database(this.$store.state.primaryDB).ref('/IduamTests/' + this.cuadrante+ '/testInProgress/').on("child_changed", (snapshot) => {
        let testInfo = { ...{test_id: snapshot.key, ...snapshot.val()} }
				const index = this.testsInProgress.findIndex((test) => {
					return test.test_id == snapshot.key
				})
				if(index == -1){
					this.testsInProgress.push(testInfo)
				}
				else{
					this.testsInProgress.splice(index, 1, testInfo)
				}
        this.$store.commit('setTestsInProgress', this.testsInProgress)
      })
      firebase.database(this.$store.state.primaryDB).ref('/IduamTests/' + this.cuadrante+ '/testInProgress/').on("child_removed", (snapshot) => {
				const index = this.testsInProgress.findIndex((test) => {
					return test.test_id == snapshot.key
				})
				if(index != -1){
					this.testsInProgress.splice(index, 1)
				}
        this.$store.commit('setTestsInProgress', this.testsInProgress)
      })
		},
    childAddedListenner(){
      const self = this
      const { userData } = this.$store.state;
			this.setTestsInProgressListenners()
      firebase.database(this.$store.state.primaryDB).ref(`/Iduam/Alertas/${userData?.info?.user_id}`).on("child_added", function(snapshot) {
        let alert = snapshot.val()
        console.log(alert, 'asdasd');
        
        alert.type = 'alerta'

/*         if (userData?.role?.user_role_id === 4 ||
          (userData?.role?.user_role_id === 3 && alert.cuadrante) ||
          (userData?.role?.user_role_id === 2 && alert.operational_groups)) {          
            this.addAlert(alert);
          } */
        self.addAlert(alert)
      })
      firebase.database(this.$store.state.primaryDB).ref('/Iduam/Buttons/' + this.cuadrante).on("child_added", function(snapshot) {
        let boton = snapshot.val()
        if(boton.latitude && boton.longitude){
          self.botonesSOS.push(boton)
        }
        else{
          if(boton.deviceID){
            firebase.database(this.$store.state.primaryDB).ref('/Iduam/Buttons/1/' + boton.deviceID).update({command: '[3G*' + boton.deviceID + '*0002*CR]'})
          }
        }
      })
      firebase.database(this.$store.state.primaryDB).ref('/Iduam/Buttons/' + this.cuadrante).on("child_changed", function(snapshot) {
        let boton = snapshot.val()
        if(boton.latitude && boton.longitude){
          const found = self.botonesSOS.findIndex(function(item, index){
            return item.deviceID === boton.deviceID
          })
          if(found !== -1){
            self.botonesSOS[found] = boton
            self.botonesSOS.sort()
          }
          else{
            self.botonesSOS.push(boton)
          }
        }
      })
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante).on('child_added',function(data){
        let monitor = data.val()
        monitor.key = data.key
        if(monitor.latitud && monitor.longitud){
          monitor.type = 'device'
          self.allDevices.push(monitor)
          if(monitor.deviceData && monitor.deviceData.is_monitor){
            self.monitores.push(monitor)
          }
          else{
            self.personales.push(monitor)
          }
        }
      })
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante).on('child_changed',function(data){
        const datos = data.val()
        if(datos.latitud && datos.longitud){
          const found = self.monitores.findIndex(function(item, index){
            return item.deviceID === datos.deviceID
          })
          if(found !== -1){
            self.monitores[found] = datos
            self.monitores.sort()
          }
          const foundP = self.personales.findIndex(function(item, index){
            return item.deviceID === datos.deviceID
          })
          if(foundP !== -1){
            self.personales[foundP] = datos
            self.personales.sort()
          }
          if(found === -1 && foundP === -1){
            self.allDevices.push(datos)
            if(datos.deviceData && datos.deviceData.is_monitor){
              self.monitores.push(datos)
            }
            else{
              self.personales.push(datos)
            }
          }
        }
      })
    },
    childRemovedListenner(){
      const self = this
      firebase.database(this.$store.state.primaryDB).ref(`/Iduam/Alertas/`).on("child_removed", function(data) {
        self.alertas = self.alertas.filter(function(item){
          return item.data.id_usuario != data.key
        })
      })
      firebase.database(this.$store.state.primaryDB).ref('/Iduam/Buttons/' + this.cuadrante).on("child_removed", function(data) {
        self.botonesSOS = self.botonesSOS.filter(function(item){
          return item.deviceID != data.key
        })
      })
      firebase.database(this.$store.state.primaryDB).ref('Iduam').child('MonitoreoOjo/' + this.cuadrante).on('child_removed',function(data){
        const removed = data.val()
        self.allDevices = self.allDevices.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        self.monitores = self.monitores.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
        self.personales = self.personales.filter(function(item,index){
          return item.deviceID !== removed.deviceID
        })
      })
    },
    addAlert(alert){
			if(alert && alert.geo_usuario){
				const alertMarker = {
					position:{
						lat: alert?.geo_usuario.lat,
						lng: alert?.geo_usuario.lng
					},
					data: alert
				}
				const center = {
					center: {
						lat: alert.geo_usuario.lat,
						lng: alert.geo_usuario.lng
					},
					zoom: 18
				}
				this.$store.commit('setMapCenter', center)
				this.alertas.push(alertMarker)
			}
    },
    showModalAlert(alerta){
      console.log(alerta)
      this.$events.fire('showAlert', alerta.data)
    }
  }
}
</script>

<style>
.home .c-main, .home .c-main > div{padding: 0!important;}
.map-container {
  width: 100%;
  height: 700px;
}
.dropclusters{
  position: absolute;
  bottom: 30px;
  left: 10px;
}
.img.gray {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.modal-audio {
  z-index: 9999999999999 !important;
}

.badge-markers {
  position:absolute;
  color: #fff;
  border-radius: 50%;
  padding: 8px;
  vertical-align: top;
  right: 20px;
}

.loader {
  width: 2px;
  padding: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ffffff;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {to{transform: rotate(1turn)}}

.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip1 .tooltiptext1::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
}

.tooltip1:hover .tooltiptext1 {
  visibility: visible;
  opacity: 1;
}
</style>