import {endpoints} from './endpoints'
import request from '../helpers/request'

export function getUserFeedbackGeo(){
	return new Promise((resolve, reject) => {
    request({
      url: endpoints.getUserFeedbackGeo,
      method: 'get',
    })
		  .then(async function (response) {
		    resolve(response)
		  })
		  .catch(function (error) {
		    reject(error)
		  });
	})
}